import React from "react";
import {Helmet} from "react-helmet";

import {Layout} from "@components";
import {SectionFiveCt, SectionFourCt, SectionThreeCt, SectionTwoCt, TopSection,} from "@templates/acquiring";
import {QuizSection} from "@templates/main";

const AcquiringPage = () => (
    <>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>Интернет эквайринг для самозанятых, прием платежей самозанятых онлайн, платежная система для
                самозанятых</title>
            <meta name="description"
                  content="Нужен интернет эквайринг для самозанятых? Наш сайт поможет вам с этим. Сервис по приёму платежей. Подпись документов онлайн"/>
            <link rel="canonical" href="https://xn--80aapgyievp4gwb.xn--p1ai/acquiring"/>
        </Helmet>
        <Layout>
            <TopSection/>
            <SectionTwoCt/>
            <SectionThreeCt/>
            <SectionFourCt/>
            <QuizSection/>
            <SectionFiveCt/>
        </Layout>
    </>
);

export default AcquiringPage;
